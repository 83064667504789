#layout_footer{
    // background-color: darken(#ee3742, 20%);
    background-color: opacity(#ee3742);
    transition: all .3s ease-in-out;
    border-top: .25rem solid #ee3742;
    #footer-list{
        border: none;
        border-radius: 0;
        margin-top: 1rem;
        max-width: 360px;
        a{
            padding: 0.5rem 0;
            border: none;
            background-color: transparent;
            // color: rgba(255, 255, 255, 0.8);
            // color: #ee3742;
            &:hover{
                color: $primary;
            }
        }
    }
    #copyright{
        border-top: .125rem solid $primary;
    }
}
