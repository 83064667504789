html{
    @include media-breakpoint-only(xs){
        font-size: 14px;
    }
    @include media-breakpoint-only(sm){
        font-size: 15px;
    }
    @include media-breakpoint-up(md){
        font-size: 16px;
    }
    @include media-breakpoint-only(xl){
        font-size: 18px;
    }
}

:root{
    --swiper-theme-color: --blue;
}

input, textarea{
    border-left: 8px solid $component-active-bg !important;
}

blockquote{
    font-size: 1.25rem;
    font-style: italic;
    color: $primary;
    line-height: 1.2;
}

.ease-out{
    transition: all 0.3s ease-out;
}
.navbar{
    z-index: 1030;
}
.nav-link{
    color: rgba(0, 0, 0, 0.4);
    &.active::after{
        content: "";
        height: 2px;
        background-color: $primary;
        display: block;
    }
}

.link-unstyled {
    &,
    &:visited,
    &:hover,
    &:active,
    &:focus,
    &:active:hover {
      font-style: inherit;
      color: inherit;
      background-color: transparent;
      font-size: inherit;
      text-decoration: none;
      font-variant: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-family: inherit;
      border-radius: inherit;
      border: inherit;
      outline: inherit;
      box-shadow: inherit;
      padding: inherit;
      vertical-align: inherit;
    }
  }

.shadow-custom{
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.shadow-custom-up{
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
}
.svg-shadow{
    -webkit-filter: drop-shadow(0 3px 2px rgba(0,0,0, 0.16));
    filter: drop-shadow(0 3px 2px rgba(0,0,0, 0.16));
}
// card
.card{
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.div-img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.s-1-1{
        width: 100%;
        padding-bottom: 100%;
    }
    &.s-2-1{
        width: 100%;
        padding-bottom: 50%;
    }
    &.s-3-1{
        width: 100%;
        padding-bottom: calc(100%/3);
    }
    &.s-16-9{
        width: 100%;
        padding-bottom: calc((100%/16)*9);
    }
    &.s-4-3{
        width: 100%;
        padding-bottom: 75%;
    }&.s-4rem{
        width: 4rem;
        height: 4rem;
    }
}

.b-bot-p{
    border-bottom: 2px solid $primary;
    display: inline-block;
    padding: 0 1rem .5rem;
}

.rounded-1rem{
    border-radius: 1rem;
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $ch-sizes, $length in $ch-sizes{
			.mw#{$infix}-#{$ch-sizes}{
				max-width: $ch-sizes+ch;
			}
		}
	}
}

.td-fit-content{
    width: 1%;
    white-space: nowrap;
}