#sideA, #sideB, #sideA-modal{
    .grid{
        &::after{
            content: '';
            display: block;
            clear: both;
        }
        .grid-sizer, .grid-item{
            @include media-breakpoint-between(xs, sm){
                width: calc(50% - .5rem);
            }
            @include media-breakpoint-only(md){
                width: calc(33% - .51rem);
            }
            @include media-breakpoint-between(lg, xl){
                width: calc(25% - .75rem);
            }
            margin-bottom: 1rem;
            border-radius: .5rem;
            img{
                border-radius: .5rem;
            }
        }
    }
}
