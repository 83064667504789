// sidebar
.wrapper{
    display: block;
}
#sidebar{
    width: 16.25rem;
    height: 100vh;
    position: fixed;
    z-index: 1020;
    // top: 3.9rem;
    left: 0;
    background-color:white;
    transition: all 0.3s ease-in-out;
    margin-left: -16.25rem;
    &.active{
        margin-left: 0;
    }
}
.overlay {
    display: block;
    position: fixed;
    /* full screen */
    width: 100vw;
    height: 100vh;
    /* transparent black */
    background: rgba(0, 0, 0, 0.4);
    /* middle layer, i.e. appears below the sidebar */
    z-index: -1;
    opacity: 0;
    /* animate the transition */
    transition: all 0.5s ease-in-out;
    &.active {
        z-index: 998;
        opacity: 1;
    }
}

.nav-item-side{
    border-left: 0 solid $primary;
    transition: all .1s ease-in-out;
    padding: 0;
    >a{
        padding: 1rem 1.5rem !important;
    }
    &:hover{
        border-left: .5rem solid $primary;
    }
    &.active{
        >a>h5 {
            color: $primary !important;
        }
    }
}
