// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
// @import url('//db.onlinewebfonts.com/c/39a2c7f346d5cfae7045aeb2fb50d9ad?family=LuloCleanW01_OneBold');
@import url('//db.onlinewebfonts.com/c/e55e9079ee863276569c8a68d776ef04?family=Futura+Md+BT');
@import url('//db.onlinewebfonts.com/c/53cc4485c682c9e4acd3a75ccaf51ec0?family=Glacial+Indifference');
// @font-face {
//     font-family: "GlacialIndifference_Bold";
//     src: local('GlacialIndifference-Bold'), url('/public/fonts/glacial-indifference/GlacialIndifference-Bold.otf') format("opentype");
// }
// @import '/public/fonts/glacial-indifference/GlacialIndifference-Bold.otf';

// Variables
@import 'variables';

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
// @import '~bootstrap/scss/bootstrap';

//custom
@import 'custom';
@import 'layout/';
@import 'order/';
@import 'edit/';
